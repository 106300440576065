<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">{{ $t('crud.group_message.title') }}</div>
                <div class="card-body">
                    <p>{{ $t('crud.group_message.description') }}</p>
                    <form @submit.prevent="handle">
                        <b-form-group :label="$t('crud.group_message.to_all')">
                            <b-form-select v-model="form.group_type" required>
                                <option value="processing">{{ $t('crud.group_message.orders_processing', { count:
                                    count.processing }) }}
                                </option>
                                <option v-for="location in count.locations" :value="'processing_pickup_location_' + location.id">{{ $t('crud.group_message.orders_processing_pickup_location', { count: location.count, name: location.name }) }}
                                </option>
                                <option value="critical">{{ $t('crud.group_message.orders_critical', { count: count.critical }) }}</option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$t('crud.group_message.date')">
                            <b-input type="date" v-model="form.date" required></b-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.group_message.message')">
                            <b-form-textarea v-model="form.message" required rows="8"/>
                        </b-form-group>
                        <loading-button :loading="loading" :disabled="sent" type="submit" class="btn btn-primary">
                            <template v-if="sent">{{ $t('crud.group_message.success', {count: sent_count}) }}</template>
                            <template v-else>{{ $t('crud.send') }}</template>
                        </loading-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getCount();
        },
        data() {
            return {
                form: {
                    // Accept date in format YYYY-MM-DD
                    date: this.$route.query.date ? this.$route.query.date : this.$moment().format('YYYY-MM-DD'),
                    group_type: 'processing',
                    message: undefined
                },
                loading: false,
                sent: false,
                count: {},
                sent_count: undefined
            }
        },
        computed: {
            watchFormDate() {
                return this.form.date;
            }
        },
        watch: {
            watchFormDate() {
                this.getCount();
            }
        },
        methods: {
            handle() {
                let count = this.count[this.form.group_type];
                if(confirm(this.$t('crud.group_message.confirm', { count: count }))) {
                    this.loading = true;
                    return this.$http.post('orders/group-message', this.form).then((response) => {
                        this.sent_count = response.data.sent;
                        this.$toastr.s(this.$t('crud.group_message.success', {count: this.sent_count}));
                        this.loading = false;
                        this.sent = true;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
                return Promise.reject('Aborted');
            },
            getCount() {
                return this.$http.get(`orders/group-message-count/${this.form.date}`).then((response) => {
                    this.count = response.data;
                });
            }
        }
    }
</script>
